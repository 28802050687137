export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [];

export const dictionary = {
		"/(home)": [2],
		"/about": [3],
		"/calendar": [4],
		"/faq": [5],
		"/resources": [6],
		"/resources/Kendo Japanese Pronunciation and Definitions.pdf": [7],
		"/resources/kendo-beginner-guide": [8],
		"/resources/kendo-japanese-pronunciations-and-definitions": [9],
		"/resources/lineup-closing-commands": [10],
		"/resources/lineup-opening-commands": [11],
		"/resources/things-to-consider-when-buying-a-uniform": [12],
		"/resources/what-is-rei": [13]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';